module.exports = {
  "strings": {
    "logotype": "unite.com",
    "loading__text": "Please, wait a minute...",
    "try_another_photo": "Try with another photo",
    "cancel": "Cancel",

    "style_name_vector": "Vector",
    "style_name_cartoon": "Cartoon",

    "email__error_empty": "Please enter email to get notified on your collage changes.",
    "email__error_invalid_format": "Oops! Seems you've entered an invalid email address. Please try again.",

    "index_page__greeting": "A new way of taking group selfies!",
    "index_page__text": "Get together with your friends, followers, and whomever you want. Upload a photo to get started, invite anyone by sharing a link on socials, and get your perfect online group selfie!",
    "index_page__select_photo_button": "Upload a photo to start",

    "create_page__form_title": "Add <span class=\"form__title-highlight \">your email</span> and set up your group selfie.",
    "create_page__form_message": "Enter your email address below so we can let you know when someone joins your group selfie. Choose the selfie style and define how we add participants: after your approval or once someone uploads their photo.",
    "create_page__form_moderation_type": "How we add participants:",
    "create_page__form_moderation_type_pre": "After your approval",
    "create_page__form_moderation_type_pre_tip": "people will be added after your confirmation",
    "create_page__form_moderation_type_post": "Once a participant uploads a photo",
    "create_page__form_moderation_type_post_tip": "people will be added automatically upon uploading their selfie, but you can always manage the list",
    "create_page__form_photo": "Your photo",
    "create_page__form_email": "Add email:",
    "create_page__form_email__tip_title": "Why adding email?",
    "create_page__form_email__tip_text": "We will use it only to send you the notifications about your collage status. We hate SPAM and promise to keep your inbox free from any marketing messages. And of course, your email address won’t be shared with anyone.",
    "create_page__form_photos_target": "Max number of friends",
    "create_page__form_collage_type": "Choose the selfie style you like most:",
    "create_page__form_submit": "Done",
    "create_page__select_variant_title": "You’re almost there!<br />Tap the result you like best.",
    "create_page__confirm_variant_title": "Your portrait is ready to be added!",
    "create_page__confirm_variant_subtitle": "Tap ‘Next’ if you like the result or try with another photo.",
    "create_page__confirm_variant__continue_button": "Next",
    "btn_more": "more",
    "btn_less": "less",
    "btn_preview": "preview",

    "attach_page__select_variant_title": "You’re almost there!<br />Tap the result you like best to proceed.",
    "attach_page__confirm_variant_title": "Your art is ready to be added!",
    "attach_page__confirm_variant__continue_button": "Next",
    "attach_page__preview_collage__title": "Your portrait is ready to be added!",
    "attach_page__preview_collage__subtitle": "Tap ‘Next’ if you like the result or try with another photo",
    "attach_page__preview_collage__continue_button": "Next",
    "attach_page__result_text_post": "Cheers! Your photo is added. Sit tight and wait for more people to join.",
    "attach_page__result_text_pre": "Cheers! Your photo will be added once the owner approves it.",
    "attach_page__create_new_collage_button": "Start your own collage",

    "collage_page__owner_text_1_finalized": "Your friends are golden!",
    "collage_page__owner_text_2_finalized": "All of them have made it to this collage event. Share the complete work to thank them for joining you.",
    "collage_page__owner_text_1": "Great job on getting your group selfie started!",
    "collage_page__owner_text_2": "Now the key person is added, it’s time to invite people by sharing the link on Facebook, Snapchat, and other socials.",
    "collage_page__owner_text_3": "P.S. don’t forget to check your email for the admin link so that you could manage your group selfie at any moment.",
    "collage_page__guest_title_1_finalized": "Oh my, this collage is already complete.",
    "collage_page__guest_subtitle_1_finalized": "It's a sign! Don't you feel like starting your own collage event now?",
    "collage_page__guest_title_1": "Hi and welcome!",
    "collage_page__guest_subtitle_1": "Join this online group selfie by adding your portrait photo below.",
    "collage_page__guest_title_2": "Feeling a bit lost here? Let us shed some light.",
    "collage_page__guest_subtitle_2": "This is an online group selfie maker that helps people to get together with their friends, followers, and whomever they want. You can join the current group selfie by uploading your photo above or start your own epic selfie by hitting the button below.",
    "collage_page__share_label": "Share:",
    "collage_page__share_save__label": "Save & share:",
    "collage_page__download_button": "Download",
    "collage_page__new_collage_button": "Start new group selfie",
    "collage_page__back_button": "Back",
    "collage_page__moderate_button": "Manage this selfie",
    "collage_page__collage_not_found": "Collage not found",
    "collage_page__collage_is_deleted": "Collage is deleted",
    "collage_page__attach_guest_photo_button": "Upload your photo",
    "collage_page__create_new_collage_button": "Start your group selfie",

    "result_page__collage_title": "Why celebs?",
    "result_page__collage_subtitle": "Just to give you an idea of how it’s gonna look:",

    "caption_form_title": "Add caption",
    "caption_form_button": "save",

    "manage_page__title_finalized": "Your collage is complete!",
    "manage_page__text_finalized": "Not sure it's as good as it could get? You can adjust the number participants anytime.",
    "manage_page__title": "Manage your group selfie",
    "manage_page__text": "Approve or remove people to complete this online group selfie.",
    "manage_page__list_pending": "Pending approval",
    "manage_page__list_attached": "Added",
    "manage_page__list_approved": "Approved",
    "manage_page__list_detached": "Removed",
    "manage_page__share_label": "Share:",
    "manage_page__finalize_button": "Finalize",
    "manage_page__apply_button": "Apply",
    "manage_page__finalize_modal__title": "Your collage options",
    "manage_page__finalize_modal__text": "Complete with celebs or friends only? Choose how you want it.",
    "manage_page__caption_placeholder": "Collage caption",

    "snapchat_page__text": "Copy this link and open it in your browser",

    "creative_error_modal__title": "Processing failure",

    "copy_toast__title": "Link copied",
    "copy_toast__text": "A link to this page has been copied to your clipboard!",

    "error_modal__default_title": "Oops, something went wrong.",
    "error_modal__default_message": "Something went wrong. Try to reload the page or check back later.",
    "error_modal__network_title": "Network error occurred",
    "error_modal__network_message": "Please check your network connection.",
    "error_modal__reload_page": "Reload page",

    "error__error": "Error",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "The selected file is in an invalid format",
  },
};