import * as amplitude from "amplitude-js";
import * as api from "./api";

const userProperties = {};
const userPropertiesCommitWaited = [];

Object.keys(window.clientConfig.splits).forEach((key) => {
  setUserProperty("split_" + key, window.clientConfig.splits[key]);
});

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({
    ...userProperties,
    is_webview: window.clientConfig.isWebview,
    platform_browser_name: window.clientConfig.platform.name,
    platform_browser_version: window.clientConfig.platform.version,
    platform_os: window.clientConfig.platform.os,
  });
}

// --

export const userEvents = {
  WEBVIEW_TAB_FOCUS: "webview_tab_focus",

  PAGE_INDEX: "page_index",
  PAGE_CREATE: "page_create",
  PAGE_ATTACH: "page_attach",
  PAGE_COLLAGE: "page_collage",
  PAGE_MANAGE: "page_manage",
  PAGE_SNAPCHAT: "page_snapchat",

  PHOTO_SELECT: "photo_select",
  PHOTO_UPLOADED: "photo_uploaded",
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  CREATIVE_PREVIEW_SELECT: "creative_preview_select",
  CREATIVE_PROCESSED: "creative_processed",
  CREATIVE_FAILED: "creative_failed",

  COLLAGE_PROCESSED: "collage_processed",
  COLLAGE_FAILED: "collage_failed",
  COLLAGE_PHOTO_ATTACH: "collage_photo_attach",
  COLLAGE_FINALIZE: "collage_finalize",

  SHARE: "share",
  DOWNLOAD: "download",
};

export const hits = {
  // --
};

export function hitEvent(id) {
  if (window.appConfig.isDebug) {
    console.info("hitEvent", id);
  }

  if (window.appConfig.hits.isEnabled) {
    window.axios.post(`${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`)
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logEvent(eventId, eventParams, cb) {
  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }

  if (window.appConfig.analytics.isEnabled) {
    let userParams = undefined;
    if (userPropertiesCommitWaited.length > 0) {
      userParams = {};
      userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
      userPropertiesCommitWaited.length = 0;
    }

    api.logEvent(eventId, eventParams, userParams);
  }
}

export function setUserProperty(key, value) {
  userProperties[key] = value;
  userPropertiesCommitWaited.push(key);
}