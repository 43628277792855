const STATUS_FAILED = -1;
const STATUS_PENDING = 0;
const STATUS_PROCESSING = 1;
const STATUS_PROCESSED = 2;

export const aliases = {
  RESULT: "result",
  PREVIEW: "preview",
};

export function isProcessed(creative) {
  return creative.status === STATUS_PROCESSED;
}

export function isFailed(creative) {
  return creative.status === STATUS_FAILED;
}

export function isProcessing(creative) {
  return creative.status === STATUS_PENDING || creative.status === STATUS_PROCESSING;
}

export function isPending(creative) {
  return creative.status === STATUS_PENDING;
}