import React, {Component} from 'react';
import AppContext from "../contexts/AppContext";

export default class Modal extends Component {

  constructor(props) {
    super(props);

    this.overlayRef = React.createRef();

    this.dismiss = this.dismiss.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
  }

  dismiss(trigger) {
    if (this.props.onDismiss) {
      if (this.props.onDismiss(this.state, trigger) !== false) {
        this.context.popModal(() => {
          this.props.onDismissed && this.props.onDismissed(this.state, trigger);
        });
      }
    } else {
      this.context.popModal(() => {
        this.props.onDismissed && this.props.onDismissed(this.state, trigger);
      });
    }
  }

  handleOverlayClick(e) {
    if (e.target === this.overlayRef.current) {
      if (this.props.canBeClosedByOverlay) {
        this.dismiss();
      }
    }
  }

  handleCloseButtonClick(e) {
    this.dismiss();
  }

  renderModal() {
    return <React.Fragment />;
  }

  render() {
    return <div
      ref={this.overlayRef}
      className={`modal ${this.props.className || ""} ${this.className || ""}`}
      onClick={this.handleOverlayClick}>

      {/* <button
        hidden={!this.props.canBeClosedByCrossButton}
        className="modal-close-button"
        onClick={this.handleCloseButtonClick}>
        <SvgSprite viewBox="0 0 20 20" icon="icon-close" />
      </button> */}

      <div className="modal__container" children={this.renderModal()} />
    </div>;
  }
}

Modal.contextType = AppContext;