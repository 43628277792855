export function debounce(name, delay, func) {
  window.appDebounceTimers = window.appDebounceTimers || {};
  clearTimeout(window.appDebounceTimers[name]);
  window.appDebounceTimers[name] = setTimeout(func, delay);
}

export function resolveFileUrl(file) {
  switch (typeof file) {
    case "string": return file;
    case "object": return file.url;
    default: return "";
  }
}

export function appUrl(path) {
  if (path.length > 0 && path[0] === "/") {
    path = path.substring(1);
  }

  return `${window.appConfig.paths.app}/${path}`;
}

export function assetUrl(path) {
  if (path.length > 0 && path[0] === "/") {
    path = path.substring(1);
  }

  const url = new URL(`${window.appConfig.paths.assets}/${path}`);

  if (process.env.REACT_APP_ASSETS_VERSION) {
    url.searchParams.set("v", process.env.REACT_APP_ASSETS_VERSION);
  }

  return url.toString();
}

export function buildScreenSizeImagesPath(path, variants) {
  const pos = path.lastIndexOf(".");
  const pair = [path.substring(0, pos), "", path.substring(pos)];
  const res = {
    base: path,
  };

  variants.forEach((variant) => {
    if (variant !== "1x") {
      pair[1] = "@" + variant;
    }

    res[variant] = pair.join("");
  });

  return res;
}

export function buildScreenSizeImagesSrcString(obj) {
  return Object
    .keys(obj)
    .filter((key) => key !== "base")
    .map((key) => `${obj[key]} ${key}`)
    .join(", ");
}