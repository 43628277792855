import {SvgSprite} from "./SvgSprite";
import i18n from "../i18n";
import React from "react";

export function CopyToast({onCloseButtonClick, title, text}) {
  return <div className="copied-block">
    <SvgSprite className="copied-block__icon" viewBox="0 0 16 16" icon="icon-copied" />
    <div className="copied-block__content">
      <h4
        className="copied-block__title"
        dangerouslySetInnerHTML={{__html: title || i18n.t("copy_toast__title")}} />
      <p
        className="copied-block__text"
        dangerouslySetInnerHTML={{__html: text || i18n.t("copy_toast__text")}} />
    </div>
    <button className="copied-block__btn-close" onClick={onCloseButtonClick}>
      <SvgSprite className="copied-block__icon-close" viewBox="0 0 16 16" icon="icon-close" />
    </button>
  </div>;
}