import React, {Component} from 'react';
import {webviewCallWithCallback} from "../utils/webview";
import {logEvent, userEvents} from "../utils/log";

export default class FileChooseButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      image: null,
    };

    this.fileFieldRef = React.createRef();

    this.handleBrowserFileSelected = this.handleBrowserFileSelected.bind(this);
    this.handleWebviewFileSelected = this.handleWebviewFileSelected.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleError = this.handleError.bind(this);
    this.triggerSelectFile = this.triggerSelectFile.bind(this);
  }

  triggerSelectFile() {
    if (window.clientConfig.isWebview) {
      const params = {
        func: this.props.callbackName,
        use_crop: 0,
        num_photos: 1,
        show: "camera",
        tab: "faces",
        neurocamera: 1,
      };

      webviewCallWithCallback("nativePhotoSelect", params, this.handleWebviewFileSelected);
    } else {
      if (this.fileFieldRef.current) {
        this.fileFieldRef.current.value = "";
        this.fileFieldRef.current.click();
      }
    }
  }

  handleError(code, message) {
    this.props.onError && this.props.onError(code, message);
  }

  handleBrowserFileSelected(file) {
    logEvent(userEvents.PHOTO_SELECT, {place: this.props.place});

    this.props.onFileSelected && this.props.onFileSelected(file);
  }

  handleWebviewFileSelected(data) {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    logEvent(userEvents.PHOTO_SELECT, {place: this.props.place});

    const photo = data.photos.pop();

    this.props.onFileSelected && this.props.onFileSelected(photo.image_url);
  }

  handleClick(e) {
    this.props.onClick && this.props.onClick(e);
    this.triggerSelectFile();
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    return <React.Fragment>
      <button
        type="button"
        className={this.props.className}
        onClick={this.handleClick.bind(this)}
        children={this.props.children} />

      <input
        className="file-field-hidden"
        type="file"
        accept="image/*"
        ref={this.fileFieldRef}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => this.handleBrowserFileSelected(e.target.files[0])} />
    </React.Fragment>;
  }
}
