import React from 'react';
import FileChooseButton from "../components/FileChooseButton";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import {logEvent, userEvents} from "../utils/log";
import i18n from "../i18n";
import {assetUrl, buildScreenSizeImagesPath, buildScreenSizeImagesSrcString} from "../utils/etc";
import LogotypeLink from "../components/LogotypeLink";

export default class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.handleFileSelected = this.handleFileSelected.bind(this);
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_INDEX);
  }

  handleFileSelected(file) {
    this.props.history.push(routes.CREATE, {file});
  }

  render() {
    const backgroundWebpMobEx = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob_ex.webp"), ["1x", "2x", "3x"]);
    const backgroundPngMobEx = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob_ex.png"), ["1x", "2x", "3x"]);
    const backgroundWebpMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob.webp"), ["1x", "2x", "3x"]);
    const backgroundPngMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob.png"), ["1x", "2x", "3x"]);
    const backgroundWebp = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles.webp"), ["1x", "2x", "3x"]);
    const backgroundPng = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles.png"), ["1x", "2x", "3x"]);

    return <main className="main-section main-page">

      <div className="container">
        <LogotypeLink disabled />
        <div className="main-page__content">
          <h2 className="title-h2 main-page__title"
              dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
          <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
          <FileChooseButton 
            className="main-page__btn btn btn_height-big btn_width-big btn_paint"
            children={i18n.t("index_page__select_photo_button")}
            place="IndexPage"
            onFileSelected={this.handleFileSelected} />
        </div>
        <div className="bubble bubble-ex-large main-page__bubble1" />
        <div className="bubble bubble-small main-page__bubble2" />
        <div className="bubble bubble-large main-page__bubble3" />
        <div className="bubble bubble-small main-page__bubble4" />
        <div className="bubble bubble-medium main-page__bubble5" />
      </div>

      <picture tabIndex="-1">
        <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMobEx)} media="(max-height: 800px) and (max-width: 430px)" type="image/webp" />
        <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMobEx)} media="(max-height: 800px) and (max-width: 430px)" />
        <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMob)} media="(max-width: 720px)" type="image/webp" />
        <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMob)} media="(max-width: 720px)" />
        <source srcSet={buildScreenSizeImagesSrcString(backgroundWebp)} type="image/webp" />
        <img
          className="main-page__bg"
          srcSet={buildScreenSizeImagesSrcString(backgroundPng)}
          src={backgroundPng.base}
          alt="Background" />
      </picture>
    </main>;
  }
}

IndexPage.contextType = AppContext;