import React from "react";
import i18n from "../i18n";
import AppContext from "../contexts/AppContext";
import {SvgSprite} from "./SvgSprite";

export default class Loading extends React.Component {

  render() {
    // у нас модалки блюрят фон и если в фоне анимация лоадера то браузер начинает сильно просидать
    // поэтому скрываем анимацию, если есть хотя бы одна модалка
    const hideAnimation = this.context.modals.length > 0 && !this.props.evenWhenModal;

    return <section className={"loader" + " " + this.props.className}>
      <div className="loader__blur" hidden={hideAnimation}>
        <div className="loader__dots">
          <div className={"loader__dot"} />
          <div className={"loader__dot"} />
          <div className={"loader__dot"} />
          <div className={"loader__dot"} />
          <div className={"loader__dot"} />
          <SvgSprite className="loader__svg" viewBox="0 0 300 150" icon="loader" />
        </div>
      </div>
      <p className="loader__text" dangerouslySetInnerHTML={{__html: this.props.text || i18n.t("loading__text")}} />
    </section>;
  }
}

Loading.contextType = AppContext;