import React from 'react';
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import Loading from "../components/Loading";
import i18n from "../i18n";
import * as api from "../utils/api";
import * as creativeUtils from "../utils/creative";
import * as collageUtils from "../utils/collage";
import FileChooseButton from "../components/FileChooseButton";
import ErrorModal from "../components/ErrorModal";
import CreativeErrorModal from "../components/CreativeErrorModal";
import {logEvent, userEvents} from "../utils/log";
import LogotypeLink from "../components/LogotypeLink";

const FETCH_INTERVAL = 1000;

export default class AttachPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isAttached: false,
      collage: null,
      file: null,
      photo: null,
      creatives: [],
      preview: null,
    };

    this.fetchTimer = null;
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_ATTACH);

    if (this.props.location.state && this.props.location.state.file && this.props.location.state.collage) {
      const {file, collage} = this.props.location.state;

      this.setState({
        isLoading: true,
        collage,
        file,
      }, this.createPhoto);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  createPhoto = () => {
    api.createPhoto(this.state.file, {style: this.state.collage.style})
      .then(this.handlePhotoStatus)
      .catch((err) => {
        logEvent(userEvents.PHOTO_UPLOAD_FAILED, {place: "AttachPage"});
        this.handleError(err);
      });
  };

  attachToCollage = (creative) => {
    this.setState({isLoading: true}, () => {
      api.attachCreativeToCollage(creative.id, this.state.collage.hash)
        .then((res) => this.setState({
          isLoading: false,
          isAttached: true,
        }))
        .catch(this.handleError);
    });
  };

  handleAnotherFileSelected = (file) => {
    this.setState({
      isLoading: true,
      file: file,
      photo: null,
      creatives: [],
      preview: null,
    }, this.createPhoto);
  };

  handleCreateNewCollageButtonClick = () => {
    this.props.history.replace(routes.CREATE, {photo: this.state.photo});
  };

  handleCreativePreviewSelect = (preview) => {
    logEvent(userEvents.CREATIVE_PREVIEW_SELECT, {
      place: "AttachPage",
      template_id: preview.templateId,
    });

    this.setState({isLoading: true}, () => {
      api.submitPhotoTemplate(this.state.photo.id, preview.templateId)
        .then(this.handleCreativeStatus)
        .catch(this.handleError);
    });
  };

  handlePhotoStatus = ({photo, creatives}) => {
    if (this.state.photo === null) {
      logEvent(userEvents.PHOTO_UPLOADED, {place: "AttachPage"});
    }

    const nextState = {photo, creatives};
    const creative = creatives[0];
    const isResultCreative = creative.alias === creativeUtils.aliases.RESULT;

    if (creativeUtils.isProcessed(creative)) {
      if (isResultCreative) {
        logEvent(userEvents.CREATIVE_PROCESSED);
        this.handleCreativeSelect(creative);
      } else {
        nextState.isLoading = false;
      }
    } else if (creativeUtils.isFailed(creative)) {
      isResultCreative && logEvent(userEvents.CREATIVE_FAILED);
      this.handleCreativeError(creative);
    } else {
      this.fetchTimer = setTimeout(() => {
        api.fetchPhoto(nextState.photo.id)
          .then(this.handlePhotoStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCreativeStatus = ({photo, creatives}) => {
    const nextState = {photo, creatives};
    const creative = creatives.find((creative) => creative.alias === creativeUtils.aliases.RESULT);

    if (creativeUtils.isProcessed(creative)) {
      logEvent(userEvents.CREATIVE_PROCESSED);
      this.handleCreativeSelect(creative);
    } else if (creativeUtils.isFailed(creative)) {
      logEvent(userEvents.CREATIVE_FAILED);
      this.handleCreativeError(creative);
    } else {
      this.fetchTimer = setTimeout(() => {
        api.fetchPhoto(this.state.photo.id)
          .then(this.handleCreativeStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCreativeConfirmButtonClick = () => {
    this.handleCreativeSelect(this.state.creatives[0]);
  };

  handleCollagePreviewConfirmButtonClick = () => {
    logEvent(userEvents.COLLAGE_PHOTO_ATTACH, {
      collage_id: this.state.collage.id,
    });

    const creative = this.state.creatives.find((creative) => creative.alias === creativeUtils.aliases.RESULT);
    this.attachToCollage(creative);
  };

  handleCreativeSelect = (creative) => {
    this.setState({isLoading: true}, () => {
      api.createCollagePreview(this.state.collage.hash, creative.id)
        .then(this.handleCollagePreviewStatus)
        .catch(this.handleError);
    });
  };

  handleCollagePreviewStatus = ({collage_preview}) => {
    const nextState = {preview: collage_preview};

    if (collageUtils.isProcessed(collage_preview)) {
      nextState.isLoading = false;
    } else if (collageUtils.isFailed(collage_preview)) {
      // todo
    } else {
      this.fetchTimer = setTimeout(() => {
        api.fetchCollagePreview(collage_preview.id)
          .then(this.handleCollagePreviewStatus)
          .catch(this.handleError);
      }, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCreativeError = (creative) => {
    const key = "AttachPage-CreativeErrorModal-" + creative.id;

    this.context.pushModal(<CreativeErrorModal
      key={key}
      creative={creative}
      onFileSelected={this.handleAnotherFileSelected}
    />);
  };

  handleError = (err) => {
    if (window.appConfig.isDebug) {
      console.error(err);
    }

    this.context.pushModal(<ErrorModal
      key="AttachPage-ErrorModal"
      error={err}
      buttons={<React.Fragment>
        <button
          className="btn btn_width-big btn_paint"
          children={i18n.t("error_modal__reload_page")}
          onClick={() => window.location.reload()} />
      </React.Fragment>}
    />);
  };

  renderCreativePreviewsList = () => {
    const variants = this.state.creatives[0].extra.variants;
    const previews = Object.keys(variants).map((templateId) => {
      return {templateId, imageUrl: variants[templateId]}
    });

    return <main className="main-section create-page portraits">
      <div className="container">
        <LogotypeLink />

        <div className="portraits__title-container">
          <h2 className="title-h2 portraits__title"
              dangerouslySetInnerHTML={{__html: i18n.t("attach_page__select_variant_title")}} />
          <FileChooseButton
            className="btn btn_width-big btn_transparent"
            children={i18n.t("try_another_photo")}
            place="AttachPage_CreativePreviewsList"
            onFileSelected={this.handleAnotherFileSelected} />
        </div>

        <div className="portraits__list">
          {previews.map((preview) => <div
            key={preview.templateId}
            className="portraits__item"
            onClick={() => this.handleCreativePreviewSelect(preview)}>
            <img className="portraits__image" src={preview.imageUrl} alt={preview.templateId} />
          </div>)}
        </div>
      </div>
    </main>;
  };

  renderCreativePreview = () => {
    const creative = this.state.creatives[0];

    return <main className="main-section create-page portraits portraits_preview">
      <div className="container">
        <LogotypeLink />

        <div className="portraits__container">
          <div className="portraits__title-container">
            <h2 className="title-h2 portraits__title"
                dangerouslySetInnerHTML={{__html: i18n.t("attach_page__confirm_variant_title")}} />
            <div>
              <FileChooseButton
                className="btn btn_width-big btn_transparent"
                children={i18n.t("try_another_photo")}
                place="AttachPage_CreativePreview"
                onFileSelected={this.handleAnotherFileSelected} />
              <button
                className="btn btn_width-big btn_paint"
                children={i18n.t("attach_page__confirm_variant__continue_button")}
                onClick={this.handleCreativeConfirmButtonClick} />
            </div>
          </div>

          <img
            className="portraits__preview-image"
            src={creative.file.url}
            alt="Preview" />
        </div>
      </div>
    </main>;
  };

  renderCollagePreview = () => {
    return <main className="main-section create-page portraits portraits_preview">
      <div className="container">
        <LogotypeLink />

        <div className="portraits__container">
          <div className="portraits__title-container">
            <h2 className="title-h2 portraits__title"
                dangerouslySetInnerHTML={{__html: i18n.t("attach_page__preview_collage__title")}} />
            <p>{i18n.t("attach_page__preview_collage__subtitle")}</p>
            <div>
              <FileChooseButton
                className="btn btn_width-big btn_transparent"
                children={i18n.t("try_another_photo")}
                place="AttachPage_CollagePreview"
                onFileSelected={this.handleAnotherFileSelected} />
              <button
                className="btn btn_width-big btn_paint"
                children={i18n.t("attach_page__preview_collage__continue_button")}
                onClick={this.handleCollagePreviewConfirmButtonClick} />
            </div>
          </div>

          <img
            className="portraits__preview-image"
            src={this.state.preview.file.url}
            alt="Preview" />
        </div>
      </div>
    </main>;
  };

  renderAttachResult = () => {
    const text = this.state.collage.is_premoderation
      ? i18n.t("attach_page__result_text_pre")
      : i18n.t("attach_page__result_text_post");

    return <main className="main-section create-page portraits portraits_preview">
      <div className="container">
        <LogotypeLink />

        <div className="portraits__container">
          <div className="portraits__title-container">
            <h2 className="title-h2 portraits__title" dangerouslySetInnerHTML={{__html: text}} />
            <div>
              <button
                className="btn btn_width-big btn_transparent"
                children={i18n.t("attach_page__create_new_collage_button")}
                onClick={this.handleCreateNewCollageButtonClick} />
            </div>
          </div>

          <img
            className="portraits__preview-image"
            src={this.state.preview.file.url}
            alt="Preview" />
        </div>
      </div>
    </main>;
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.isAttached) {
      return this.renderAttachResult();
    }

    if (this.state.preview) {
      return this.renderCollagePreview();
    }

    const creative = this.state.creatives[0];

    if (creative.alias === creativeUtils.aliases.PREVIEW) {
      return this.renderCreativePreviewsList();
    } else {
      return this.renderCreativePreview();
    }
  }
}

AttachPage.contextType = AppContext;