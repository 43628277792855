import Modal from "./Modal";
import {SvgSprite} from "./SvgSprite";
import i18n from "../i18n";
import FileChooseButton from "./FileChooseButton";
import React from "react";

export default class CreativeErrorModal extends Modal {

  handleFileSelected = (file) => {
    this.props.onFileSelected(file);
    this.dismiss();
  };

  renderModal() {
    return <div className="modal__content">
      <SvgSprite className="modal__image modal__image_desktop" viewBox="0 0 594 262" icon="error-image" />
      <SvgSprite className="modal__image modal__image_mob" viewBox="0 0 366 256" icon="error-image-mob" />
      <h2 className="title-h2 modal__title" dangerouslySetInnerHTML={{__html: i18n.t("creative_error_modal__title")}} />
      <p className="modal__text" children={this.props.creative.error.message} />
      <FileChooseButton
        className="btn btn_width-big btn_paint"
        children={i18n.t("try_another_photo")}
        place="CreativeErrorModal"
        onFileSelected={this.handleFileSelected} />

      <div className="bubble bubble-ex-large modal__bubble1" />
    </div>
  }
}