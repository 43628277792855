const STATUS_FAILED = -1;
const STATUS_PROCESSING = 0;
const STATUS_PROCESSED = 1;

export function isProcessed(collage) {
  return collage.status === STATUS_PROCESSED;
}

export function isFailed(collage) {
  return collage.status === STATUS_FAILED;
}

export function isProcessing(collage) {
  return collage.status === STATUS_PROCESSING;
}